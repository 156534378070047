<template>
    <div>
        <!--begin::Header-->
        <div
            class="border-0 m-0 d-flex flex-wrap align-items-center"
            :class="mobile ? 'justify-content-between' : 'justify-content-between'"
        >
            <b-form-group :style="mobile ? 'width:100%' : 'width:40%'" label-for="filterInput" class="my-2">
                <b-input-group>
                    <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" style="z-index:1" @click="filter = ''">Effacer</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <ul class="nav nav-pills nav-pills-sm nav-dark-75 align-items-center my-2">
                <li class="nav-item mr-2">Trier par :</li>
                <li class="nav-item mr-2">
                    <b-form-select
                        v-model="sortBy"
                        :options="sortByAvailable"
                        class="text-dark font-weight-bold"
                        size="sm"
                    />
                </li>
                <li class="nav-item">
                    <b-button @click="sortDesc = !sortDesc" :class="sortDesc ? 'active' : ''" size="sm" variant="outline-primary">
                        <span class="svg-icon svg-icon-md svg-icon-primary m-0">
                            <i class="p-0 fas" :class="sortDesc ? 'fa-sort-down' : 'fa-sort-up'"></i>
                        </span>
                    </b-button>
                </li>
            </ul>
            <router-link to="classes/new" replace>
                <span class="btn btn-outline-primary btn-md my-2">
                    Créer une classe
                </span>
            </router-link>
        </div>
        <!--end::Header-->
        <b-table
            :items="classes"
            :fields="fields"
            class="m-0 mb-3"
            :filter="filter"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @filtered="onFiltered"
            :filter-included-fields="['title', 'description', 'goal', 'linear']"
            foot-clone
            responsive
            style="width:100%;position:relative"
        >
            <template #cell(show_details)="row">
                <button @click="row.toggleDetails" class="btn btn-icon btn-light btn-sm">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg :src="'/media/svg/icons/Navigation/' + `${row.detailsShowing ? 'Minus' : 'Plus'}` + '.svg'" />
                    </span>
                </button>
            </template>
            <template #cell(miniature)="row">
                <img :src="`${row.item.miniature}`" style="max-height:8vh" class="rounded p-1 mx-1" alt="Logo de la classe" />
            </template>
            <template #cell(description)="row">
                <div style="max-width:40em;max-height: 10vh;overflow:hidden">
                    <p class="text-dark font-weight-bolder mb-1">
                        {{ row.item.title }}
                    </p>
                    <nl2br
                        tag="p"
                        v-if="row.item.description"
                        :text="row.item.description"
                        class-name="text-truncate text-muted d-block my-2"
                    />
                </div>
            </template>
            <template #cell(goal)="row">
                <span class="text-muted font-weight-bold">{{ row.item.goal === null ? 'Inderterminé' : row.item.goal + '%' }}</span>
            </template>
            <template #cell(linear)="row">
                <span class="svg-icon" :class="row.item.linear ? 'svg-icon-primary' : 'svg-icon-danger'">
                    <inline-svg src="/media/svg/icons/Design/Flatten.svg"></inline-svg>
                    Parcours ordonné
                </span>
            </template>
            <template #cell(classroom)="row">
                <div>
                    <span v-if="row.item.classroom" class="svg-icon svg-icon-primary">
                        <inline-svg src="/media/svg/icons/classrooms.svg" />
                    </span>
                </div>
            </template>
            <template #cell(edit)="row">
                <router-link :to="{ name: 'classes/edit', params: { id: row.item.id } }" replace>
                    <button class="btn btn-icon btn-circle btn-light btn-sm">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                            <inline-svg src="/media/svg/icons/General/Visible.svg" />
                        </span>
                    </button>
                </router-link>
            </template>
            <template #row-details="row">
                <div class="border-left border-secondary pl-7 ml-5">
                    <b-table :items="row.item.lessons" :fields="fieldsLessons" show-empty borderless foot-clone hover>
                        <template #cell(title)="row">
                            <div>
                                {{ row.item.title }}
                            </div>
                        </template>
                        <template #cell(description)="row">
                            <div style="max-height: 5vh;overflow : hidden; max-width: 95% ">
                                <nl2br tag="p" :text="row.item.description" class-name="my-2 nl2br text-truncate" />
                            </div>
                        </template>
                        <template #cell(linear)="row">
                            <div>
                                <span class="svg-icon" :class="row.item.linear ? 'svg-icon-primary' : 'svg-icon-danger'">
                                    <inline-svg src="/media/svg/icons/Design/Flatten.svg"></inline-svg>
                                </span>
                            </div>
                        </template>
                        <template #cell(edit)="row">
                            <div class="d-flex ">
                                <router-link
                                    class="mx-1"
                                    v-if="!classes.find(el => el.id === row.item.classe_id).on"
                                    :to="{ name: 'lessonItems/editor', params: { id: row.item.id } }"
                                    replace
                                >
                                    <button class="btn btn-icon btn-circle btn-light btn-sm" v-b-tooltip.hover.top="'Editeur'">
                                        <span class="svg-icon svg-icon-md svg-icon-primary">
                                            <inline-svg src="/media/svg/icons/General/Visible.svg" />
                                        </span>
                                    </button>
                                </router-link>
                                <router-link :to="{ name: 'lessonItems', params: { id: row.item.id } }" replace>
                                    <button class="btn btn-icon btn-circle btn-light btn-sm" v-b-tooltip.hover.top="'Prévisualiser'">
                                        <span class="svg-icon svg-icon-md svg-icon-primary">
                                            <inline-svg src="/media/svg/icons/Media/Play.svg" />
                                        </span>
                                    </button>
                                </router-link>
                            </div>
                        </template>
                        <template #empty>
                            <b-button block variant="secondary" disabled>
                                <i class="text-dark">La classe ne possède pas de leçons</i>
                            </b-button>
                        </template>
                    </b-table>
                </div>
            </template>
        </b-table>
        <b-row class="d-flex justify-content-between align-items-center mt-2">
            <b-col sm="5" md="4">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" />
            </b-col>
            <b-form-group
                hidden
                label="Nombre de résultats par page"
                label-cols-sm="5"
                label-cols-md="5"
                label-cols-lg="5"
                label-align-sm="center"
                label-size="sm"
                label-for="perPageSelect"
                class="mr-2 align-items-center"
                :class="mobile ? 'text-center' : ''"
            >
                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions" />
            </b-form-group>
        </b-row>
    </div>
</template>
<script>
import { LIST_ALL_CLASSES } from '@/core/services/store/api/classe.service';
import { mapGetters } from 'vuex';
import Nl2br from 'vue-nl2br';

export default {
    name: 'ClassesThumbnail',
    components: {
        Nl2br
    },
    mounted() {
        this.$store.dispatch(LIST_ALL_CLASSES).then(() => {
            this.totalRows = this.classes.length;
        });
    },
    computed: {
        ...mapGetters({ classes: 'getAllClasses' }),
        mobile() {
            return window.screen.availWidth > 992 ? false : true;
        }
    },
    data() {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            footClone: true,
            pageOptions: [5, 10, 15, { value: 100, text: 'Le plus possible' }],
            filter: null,
            sortBy: 'created_at',
            sortByAvailable: {
                created_at: 'Date de création',
                title: 'Titre',
                goal: 'Objectif',
                linear: 'Parcours ordonné',
                classroom: 'Classeroom'
            },
            sortDesc: false,
            fields: [
                {
                    key: 'show_details',
                    label: '',
                    thStyle: 'width: 5%',
                    tdStyle: 'width: 5%',
                    tdClass: 'align-middle'
                },
                {
                    key: 'miniature',
                    label: '',
                    thStyle: 'width: 15%',
                    tdStyle: 'width: 15%',
                    tdClass: 'align-middle'
                },
                {
                    key: 'description',
                    label: '',
                    thStyle: 'width:30%',
                    tdStyle: 'width:30%',
                    tdClass: 'align-middle'
                },
                {
                    key: 'goal',
                    label: '',
                    thStyle: 'width: 15%',
                    tdStyle: 'width: 15%',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'linear',
                    label: '',
                    thStyle: 'width: 20%',
                    tdStyle: 'width: 20%',
                    filterByFormatted: true,
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'classroom',
                    label: '',
                    thStyle: 'width: 10%',
                    tdStyle: 'width: 10%',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'edit',
                    label: '',
                    thStyle: 'width: 5%',
                    tdStyle: 'width: 5%',
                    tdClass: 'align-middle text-center'
                }
            ],
            fieldsLessons: [
                { key: 'title', label: '', thStyle: 'max-width: 10%', tdClass: 'align-middle' },
                { key: 'description', label: '', thStyle: 'max-width: 10%', tdClass: 'align-middle' },
                { key: 'linear', label: '', thStyle: 'max-width: 10%', tdClass: 'align-middle' },
                { key: 'edit', label: '', thStyle: 'max-width: 10%', tdClass: 'align-middle' }
            ]
        };
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
        }
    },
    watch: {
        sortBy() {
            this.currentPage = 1;

            if (this.sortBy === 'classroom') {
                this.sortDesc = true;
            } else {
                this.sortDesc = false;
            }
        }
    }
};
</script>
<style scoped>
.nl2br {
    max-height: 7em;
    overflow-y: hidden;
}
body {
    overflow-x: hidden;
}
</style>
