<template>
    <div>
        <div class="border-0 m-0 d-flex flex-wrap align-items-center mt-5">
            <div class="border-0 m-0 d-flex flex-wrap align-items-center" style="width: 100%">
                <b-form-group class="my-2" id="filter-group" label-for="filterInput">
                    <b-input-group>
                        <b-form-input v-model.lazy="search_filter" type="search" id="filterInput" placeholder="Rechercher" />
                        <b-input-group-append>
                            <b-button :disabled="!search_filter" @click="search_filter = ''">Effacer</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <b-table
                :items="links.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="search_filter"
                @filtered="onFiltered"
                responsive
                hover
                show-empty
                no-border-collapse
                style="vertical-align: middle; text-align: center"
                class="mx-0 p-0 my-5"
            >
                <template #cell(valid)="row"> {{ row.item.valid == true || row.item.valid == '1' ? 'Oui' : 'Non' }} </template>
                <template #cell(expiry)="row">
                    {{ formatDate(row.item.expiry) }}
                </template>
                <template #cell(on)="row">
                    <div class="d-flex flex-column justify-content-around align-items-center">
                        <div v-if="row.item.on" class="text-primary">Actif</div>
                        <div v-else class="text-danger">Arrêté</div>
                    </div>
                </template>
                <template #cell(customers_id)="row">
                    {{ row.item.select_customer ? 'Choisi par le stagiaire' : row.item.customer.name }}
                </template>
                <template #cell(limit)="row">
                    {{ !row.item.limit && row.item.limit !== 0 ? 'Illimité' : row.item.limit }}
                </template>
                <template #cell(forceMail)="row">
                    <p class="m-0" v-if="row.item.forceMail">Avec adresse email</p>
                    <p class="m-0" v-else-if="row.item.forceNoMail">Sans adresse email</p>
                    <p class="m-0" v-else>Libre</p>
                </template>
                <template #cell(token)="row">
                    <div class="d-flex justify-content-center">
                        <b-button variant="primary" @click="token_selected = row.item.token" v-b-modal.modal-link>
                            Afficher le lien
                        </b-button>
                    </div>
                </template>
                <template #cell(Action)="row">
                    <div class="d-flex flex-row align-items-center w-25">
                        <button class="btn btn-clean btn-md svg-icon svg-icon-warning" @click="regenerateToken(row.item.id)">
                            <inline-svg src="/media/svg/icons/General/Update.svg" />
                        </button>
                        <button
                            :class="`btn btn-clean btn-md svg-icon ${row.item.on ? 'svg-icon-warning' : 'svg-icon-primary'}`"
                            @click="toogleActifLink(row.item.id, row.item.on)"
                        >
                            <inline-svg :src="`/media/svg/icons/Media/${row.item.on ? 'Pause' : 'Play'}.svg`" />
                        </button>
                        <button
                            class="btn btn-clean btn-md svg-icon svg-icon-primary"
                            @click="
                                (token_selected = row.item.token), (dataLink = links.data.find((link) => link.token === token_selected))
                            "
                            v-b-modal.modal-update-link
                            v-if="$store.getters.currentUser.role === 'admin'"
                        >
                            <inline-svg src="/media/svg/icons/General/Edit.svg" />
                        </button>
                        <button class="btn btn-clean btn-md svg-icon svg-icon-danger" @click="deleteLink(row.item.id)">
                            <inline-svg src="/media/svg/icons/General/Trash.svg" />
                        </button>
                    </div>
                </template>
                <template #empty>
                    <h5>Aucun lien</h5>
                </template>
                <template #emptyfiltered>
                    <h5>Aucun résultat pour cette recherche</h5>
                </template>
            </b-table>
            <b-row class="justify-content-between align-items-center" style="width: 100%">
                <b-col sm="5" md="4">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" />
                </b-col>
                <div>
                    <ul class="pagination">
                        <li class="page-item active">
                            <a class="page-link bg-primary">
                                Nombre de résultats :
                                {{ search_filter ? totalRows : links ? links.data.length : '' }}
                            </a>
                        </li>
                    </ul>
                </div>
                <!--  <b-form-group
                    label="Nombre de résultats par page"
                    label-cols-sm="5"
                    label-cols-md="5"
                    label-cols-lg="5"
                    label-align-sm="center"
                    label-size="sm"
                    label-for="perPageSelect"
                    style="vertical-align : middle"
                    class="mr-2 align-items-center text-center"
                >
                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"> </b-form-select>
                </b-form-group> -->
            </b-row>
        </div>
        <ModalUpdateLink
            @finish-update="getLinks()"
            :dataLink="dataLink"
            :classeId="parseInt(classeId)"
            :customerId="parseInt(classCustomerId)"
            :baseCustomerId="parseInt(customerId)"
            :classeCustomer="classeCustomer"
        />
        <ModalShowLink :link="`${plateformeHref}/connexion/${token_selected}`" />
    </div>
</template>
<script>
import { LIST_LINK } from '@/core/services/store/api/entry-link.service';
import { LIST_ONE_CLIENT_CLASSE } from '@/core/services/store/api/client-classe.service';
import ApiService from '@/core/services/api.service';
import { mapGetters } from 'vuex';
import moment from 'moment';
import ModalShowLink from '../../../../components/manager/ModalShowLink.vue';
import ModalUpdateLink from '../../../../components/manager/ModalUpdateLink.vue';

export default {
    components: { ModalShowLink, ModalUpdateLink },
    props: {
        classCustomerId: {
            type: Number,
            required: true
        },
        classeId: {
            type: Number,
            required: true
        },
        customerId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            classeCustomer: null,
            dataLink: undefined,
            selectedCustomer: 0,
            selectedClasse: 0,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, { value: 100, text: 'Le plus possible' }],
            search_filter: null,
            isBusy: false,
            sortBy: 'title',
            sortDesc: false,
            fields: [
                {
                    key: 'customers_id',
                    label: 'Etabissement de rattachement',
                    sortable: true
                },
                {
                    key: 'expiry',
                    label: "Date d'expiration",
                    sortable: true
                },
                {
                    key: 'token',
                    label: 'Url',
                    thStyle: 'width: 15em',
                    sortable: true
                },
                {
                    key: 'limit',
                    label: 'Utilisation restante',
                    thStyle: 'width: 15em',
                    sortable: true
                },
                {
                    key: 'forceMail',
                    label: "Type d'inscription",
                    thStyle: 'width: 15em',
                    sortable: true
                },
                {
                    key: 'valid',
                    label: "Validation d'inscription",
                    thStyle: 'width: 15em',
                    sortable: true
                },
                {
                    key: 'on',
                    label: 'Statut',
                    sortable: true
                },
                {
                    key: 'Action',
                    label: '',
                    thStyle: 'width: 5%',
                    sortable: false
                }
            ],
            token_selected: null
        };
    },
    mounted() {
        this.getLinks();
    },
    computed: {
        ...mapGetters({
            currentUser: 'currentUser',
            links: 'getLink'
        }),
        plateformeHref() {
            return `${process.env.VUE_APP_PLATEFORME_URL}`;
        }
    },
    watch: {
        currentPage: function () {
            return this.getLinks();
        }
    },
    methods: {
        getLinks() {
            let params = {
                classCustomerId: this.classCustomerId,
                params: {
                    page: this.currentPage,
                    'filter[classe.title]': this.search_filter
                }
            };
            this.$store.dispatch(LIST_LINK, params);
            this.$store.dispatch(LIST_ONE_CLIENT_CLASSE, this.classCustomerId).then((result) => {
                this.classeCustomer = result.data.data[0];
            });
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        toogleActifLink(link_id, status) {
            const data = { on: status ? 0 : 1 };
            this.updateLink(link_id, data);
        },
        regenerateToken(link_id) {
            this.updateLink(link_id, { new_token: true });
        },
        updateLink(link_id, data) {
            ApiService.put(`academy/entryLink/${link_id}`, data).then(() => {
                this.getLinks();
            });
        },
        deleteLink(link_id) {
            this.$bvModal
                .msgBoxConfirm('Êtes vous sûr de vouloir supprimer ce lien ?', {
                    title: 'Veuillez confirmer',
                    size: 'sm',
                    buttonSize: 'md',
                    okVariant: 'danger',
                    okTitle: 'Oui, je suis sûre',
                    cancelTitle: 'Non',
                    footerClass: 'p-2 d-flex justify-content-around',
                    bodyClass: 'font-weight-bold',
                    hideHeaderClose: false
                })
                .then((value) => {
                    if (value) {
                        ApiService.delete(`academy/entryLink/${link_id}`).then(() => {
                            this.getLinks();
                        });
                    }
                });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>
<style>
table th,
table td {
    vertical-align: middle !important;
}

#filter-group {
    min-width: 60rem;
}
</style>
