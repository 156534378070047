<template>
    <div>
        <div
            class="border-0 m-0 d-flex flex-wrap align-items-center mt-5"
            :class="mobile ? 'justify-content-center' : 'justify-content-between'"
        >
            <h2 class="mt-2 mb-7 mx-auto">CLASSES DISPONIBLES</h2>

            <div
                class="border-0 m-0 d-flex flex-wrap align-items-center"
                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                style="width: 100%"
            >
                <b-form-group :style="mobile ? 'width:100%' : 'width:60%'" class="m-0" label-for="filterInput">
                    <b-input-group>
                        <b-form-input v-model.lazy="search_filter" type="search" id="filterInput" placeholder="Rechercher" />
                        <b-input-group-append>
                            <b-button :disabled="!search_filter" @click="search_filter = ''">Effacer</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <b-table
                :items="classes && classes.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :current-page="currentPage"
                :per-page="perPage"
                responsive
                hover
                no-border-collapse
                style="vertical-align: middle; text-align: center"
                class="mx-0 p-0 mb-5"
            >
                <template #cell(title)="row">
                    <div
                        class="text-truncate scrollPanel"
                        style="
                            max-width: 25rem;
                            padding: 0 1rem;
                            white-space: break-spaces;
                            margin: 0 auto;
                            max-height: 5vh;
                            overflow-y: auto;
                        "
                    >
                        {{ row.item.classe && row.item.classe.title }}
                    </div>
                </template>
                <template #cell(description)="row">
                    <div
                        class="text-truncate scrollPanel"
                        style="
                            max-width: 25rem;
                            padding: 0 1rem;
                            white-space: break-spaces;
                            margin: 0 auto;
                            max-height: 4vh;
                            overflow-y: auto;
                        "
                    >
                        {{ row.item.classe && row.item.classe.description }}
                    </div>
                </template>
                <template #cell(customer)="row">
                    <div class="text-center">
                        <p class="mb-0">{{ row.item.customer && row.item.customer.name.toUpperCase() }}</p>
                        <span class="text-muted">(Code : {{ row.item.customer && row.item.customer.code }})</span>
                    </div>
                </template>
                <template #cell(on)="row">
                    <div class="d-flex flex-column justify-content-around align-items-center">
                        <div v-if="row.item.classe && row.item.classe.on" class="text-primary">En ligne</div>
                        <div v-else class="text-danger">Hors ligne</div>
                    </div>
                </template>
                <template #cell(expiration)="row">
                    <div class="text-center">
                        <p class="mb-0">{{ row.item.date_limit ? formatDate(row.item.date_limit) : 'Aucune' }}</p>
                    </div>
                </template>
                <template #cell(Action)="row">
                    <div class="d-flex flex-row align-items-center w-25">
                        <router-link
                            :to="{
                                name: 'classes-customers.link',
                                params: { classesCustomersId: row.item.id, classesCustomers: row.item }
                            }"
                            replace
                            v-if="(row.item.date_limit && new Date(row.item.date_limit) > new Date()) || !row.item.date_limit"
                        >
                            <button
                                v-if="!demo"
                                class="btn btn-clean btn-md svg-icon svg-icon-primary"
                                v-b-tooltip.hover.bottom="'Gérer les URL d\'inscription'"
                            >
                                <inline-svg src="/media/svg/icons/Communication/Share.svg" />
                            </button>
                        </router-link>
                        <span v-else v-b-tooltip.hover.top="'La licence de cette classe est expirée'">
                            <button v-if="!demo" class="btn disabled btn-md svg-icon svg-icon-danger">
                                <inline-svg src="/media/svg/icons/Communication/Share.svg" />
                            </button>
                        </span>
                        <span v-if="!demo" class="mr-1" v-b-tooltip.hover.bottom="'Gérer la liste d\'attente de validation d\'inscription'">
                            <router-link
                                :to="{
                                    name: 'classes-customers.queue',
                                    params: { classesCustomersId: row.item.id, classesCustomers: row.item }
                                }"
                                replace
                            >
                                <button v-if="row.item.customer" class="btn btn-clean btn-md svg-icon svg-icon-primary">
                                    <inline-svg src="/media/svg/icons/Communication/Chat-Check.svg" />
                                </button>
                            </router-link>
                        </span>
                        <span v-if="!demo" class="mr-1" v-b-tooltip.hover.top="'Gérer les erreurs d\'inscription'">
                            <router-link
                                :to="{
                                    name: 'classes-customers.assign-error',
                                    params: { classesCustomersId: row.item.id, classesCustomers: row.item }
                                }"
                                replace
                            >
                                <button v-if="row.item.customer" class="btn btn-clean btn-md svg-icon svg-icon-danger">
                                    <inline-svg src="/media/svg/icons/Communication/Chat-error.svg" />
                                </button>
                            </router-link>
                        </span>
                        <button
                            v-if="row.item.left > 0 && row.item.classe.on"
                            class="btn btn-clean btn-md svg-icon svg-icon-primary"
                            @click="selectCustomer(row.item.customer.id, row.item.classe.id, row.item.id, 'excel-import-modal')"
                            v-b-tooltip.hover.left="'Charger un fichier d\'inscription'"
                        >
                            <inline-svg src="/media/svg/icons/Files/Import.svg" />
                        </button>
                        <router-link :to="{ name: 'classes/manage', params: { customerclasse: row.item } }" replace>
                            <button
                                class="btn btn-clean btn-md svg-icon svg-icon-primary"
                                v-b-tooltip.hover.top="'Voir les détails de la classe'"
                            >
                                <inline-svg src="/media/svg/icons/General/Visible.svg" />
                            </button>
                        </router-link>
                        <router-link
                            :to="{ name: 'user/assign', params: { customerclasse: row.item } }"
                            replace
                            v-if="
                                row.item.classe &&
                                row.item.classe.on &&
                                ((row.item.date_limit && new Date(row.item.date_limit) > new Date()) || !row.item.date_limit)
                            "
                        >
                            <button
                                v-if="!demo"
                                class="btn btn-clean btn-md svg-icon svg-icon-primary"
                                v-b-tooltip.hover.bottom="'Ouvrir cette classe à un stagiaire'"
                            >
                                <inline-svg src="/media/svg/icons/General/Attachment1.svg" />
                            </button>
                        </router-link>
                        <span
                            v-else
                            v-b-tooltip.hover.top="
                                row.item.classe && !row.item.classe.on
                                    ? 'Vous ne pouvez pas ouvrir une classe hors ligne à un stagiaire'
                                    : 'La licence de cette classe est expirée'
                            "
                        >
                            <button v-if="!demo" class="btn disabled btn-md svg-icon svg-icon-danger">
                                <inline-svg src="/media/svg/icons/General/Attachment1.svg" />
                            </button>
                        </span>
                    </div>
                </template>
            </b-table>
            <b-row
                class="d-flex align-items-center"
                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                style="width: 100%"
            >
                <b-col sm="5" md="4">
                    <b-pagination v-model="currentPage" :total-rows="classes.data.length" :per-page="perPage" align="fill"> </b-pagination>
                </b-col>

                <div>
                    <ul class="pagination mr-4" :class="mobile ? 'mt-2' : ''">
                        <li class="page-item active">
                            <a class="page-link bg-primary">Nombre de résultats : {{ classes.data.length }}</a>
                        </li>
                    </ul>
                </div>
                <!--  <b-form-group
                    label="Nombre de résultats par page"
                    label-cols-sm="5"
                    label-cols-md="5"
                    label-cols-lg="5"
                    label-align-sm="center"
                    label-size="sm"
                    label-for="perPageSelect"
                    style="vertical-align : middle"
                    class="mr-2 align-items-center text-center"
                    :class="mobile ? 'text-center' : ''"
                >
                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"> </b-form-select>
                </b-form-group> -->
            </b-row>
        </div>
        <class-customer-link :customerId="selectedCustomer" :classeId="selectedClasse" />
        <excel-import-modal
            @updateClientsClasses="getClientsClasses"
            :customerId="selectedCustomer"
            :classeId="selectedClasse"
            :classesCustomersId="selectedClassesCustomers"
        />
    </div>
</template>

<script>
import { LIST_CLIENTS_CLASSES } from '@/core/services/store/api/client-classe.service';
import ExcelImportModal from '@/view/content/widgets/admin/modal/ExcelImport';
import ClassesCustomersGenerateLink from '@/view/content/widgets/admin/modal/ClassesCustomersGenerateLink';

import { mapGetters } from 'vuex';

export default {
    props: ['customerId'],
    components: {
        ExcelImportModal,
        'class-customer-link': ClassesCustomersGenerateLink
    },
    data() {
        return {
            test: null,
            selectedCustomer: 0,
            selectedClasse: 0,
            selectedClassesCustomers: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, { value: 100, text: 'Le plus possible' }],
            search_filter: null,
            isBusy: false,
            sortBy: 'title',
            sortDesc: false,
            demo: false,
            fields: [
                { key: 'title', thStyle: 'max-width: 110px', label: 'Titre', sortable: false },
                { key: 'description', thStyle: 'max-width: 110px', label: 'Description', sortable: false },
                { key: 'on', label: 'Statut', sortable: false },
                { key: 'customer', label: 'Gestionnaire', sortable: false },
                { key: 'left', label: 'Licences restantes', sortable: false },
                { key: 'expiration', label: "Date d'expiration", sortable: false },
                { key: 'Action', label: '', thStyle: 'width: 5%', sortable: false }
            ]
        };
    },
    mounted() {
        this.getClientsClasses();
        this.isDemo();
    },
    computed: {
        ...mapGetters({
            classes: 'getClientsClasses',
            currentUser: 'currentUser'
        }),
        mobile() {
            return !(window.screen.availWidth > 992);
        }
    },
    watch: {
        currentPage: () => {
            return this.getClientsClasses();
        },
        search_filter: function () {
            const filter = this.search_filter;
            setTimeout(() => {
                if (filter === this.search_filter) {
                    this.getClientsClasses();
                }
            }, 1000);
        }
    },
    methods: {
        formatDate(date) {
            //Retourne la date au format jj/mm/aaaa
            if (date) {
                return new Date(date).toLocaleDateString('fr-FR');
            }
        },
        selectCustomer(customerId, classeId, classesCustomersId, modalId) {
            this.selectedCustomer = customerId;
            this.selectedClasse = classeId;
            this.selectedClassesCustomers = classesCustomersId;
            if (modalId) {
                this.$bvModal.show(modalId);
            }
        },
        async getClientsClasses() {
            var params = {
                params: {
                    page: this.currentPage,
                    'filter[classe.title]': this.search_filter
                }
            };

            if (this.customerId) {
                params.params.customer_id_desc = this.customerId;
            }

            await this.$store.dispatch(LIST_CLIENTS_CLASSES, params);
        },
        isDemo() {
            if (this.$store.getters.currentUser.demo === true || this.$store.getters.currentUser.role === 'Commercial') {
                this.demo = true;
            }
        }
    }
};
</script>
<style scoped>
.scrollPanel::-webkit-scrollbar-track {
    background-color: #eee;
}

.scrollPanel::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
}

.scrollPanel::-webkit-scrollbar-thumb {
    background-color: #085a82;
}
</style>
