<template>
    <!--begin::Dashboard-->
    <div v-if="$store.getters.currentUser.role">
        <div class="row">
            <div class="card card-custom gutter-b col-lg-12 col-xxl-12 mt-5 p-5">
                <div class="d-flex align-items-center justify-content-between">
                    <div style="width: 10%">
                        <router-link :to="prevRoute ? prevRoute.fullPath : '/'" replace>
                            <span class="btn btn-outline-danger btn-md">
                                <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                    <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                                </span>
                                {{ $t('CONTROLLERS.BACK') }}
                            </span>
                        </router-link>
                    </div>
                    <div class="text-center">
                        <p class="h3 text-primary">Gestion des inscriptions à une classe</p>
                        <span class="text-muted font-italic">Séléctionnez un stagiaire et une classe</span>
                    </div>
                    <div style="width: 10%"></div>
                </div>
            </div>
        </div>
        <div
            v-if="selectedUser && selectedClasse && error && selectedClasse.left >= 1"
            class="card card-custom gutter-b bg-danger w-75 m-auto mb-3 py-5 text-center text-white"
        >
            Le stagiaire {{ selectedUser.lastname.toUpperCase() + ' ' + selectedUser.firstname }} possède déjà un accès à la classe
            {{ `${selectedClasse.classe ? selectedClasse.classe.title : selectedClasse.title}` }}
        </div>
        <div
            v-else-if="selectedUser && selectedClasse && error && selectedClasse.left < 1"
            class="card card-custom gutter-b bg-danger w-75 py-5 m-auto mb-3 text-center text-white"
        >
            Le gestionnaire {{ selectedClasse.customer.name.toUpperCase() }} ne possède plus de licence pour la classe
            {{ `${selectedClasse.classe ? selectedClasse.classe.title : selectedClasse.title}` }}
        </div>
        <div class="row">
            <div class="col-xxl-6">
                <div
                    class="card card-custom gutter-b mt-5 p-5 px-10"
                    :class="selectedUser && selectedClasse && error ? ' border border-danger shadow shadow-danger' : ''"
                >
                    <div class="card-body justify-content-around text-center p-0">
                        <h4 class="card-title">Stagiaire</h4>
                        <div v-if="selectedUser">
                            <div class="d-flex flex-row flex-wrap justify-content-center align-items-center my-3">
                                <div class="symbol symbol-100 mr-5">
                                    <img :src="selectedUser.avatar" width="100%" alt="image de l'utilisateur" class="symbol-label" />
                                </div>
                                <div class="d-flex flex-column justify-content-around">
                                    <p class="text-primary h2 py-1">
                                        {{ `${selectedUser.firstname + ' ' + selectedUser.lastname.toUpperCase()}` }}
                                    </p>
                                    <div class="svg-icon svg-icon-primary text-primary d-flex justify-content-start align-items-end">
                                        <inline-svg src="/media/svg/icons/Home/Building.svg" class="mr-2" />
                                        <span>{{
                                            selectedUser.customer
                                                ? 'Stagiaire de ' + selectedUser.customer.name.toUpperCase()
                                                : 'Utilisateur rattaché a aucun client'
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-clean btn-danger mt-4" @click="unselectUser()">Choisir un autre utilisateur</button>
                        </div>
                        <div
                            v-else
                            class="m-0 d-flex flex-wrap align-items-center mt-5"
                            :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                        >
                            <div
                                class="d-flex flex-wrap align-items-center mb-2"
                                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                                style="width: 100%"
                            >
                                <b-form-group :style="mobile ? 'width:100%' : 'width:60%'" class="m-0" label-for="user_filterInput">
                                    <b-input-group>
                                        <b-form-input
                                            v-model.lazy="trainees_filter"
                                            type="search"
                                            id="user_filterInput"
                                            placeholder="Rechercher"
                                        />
                                        <b-input-group-append>
                                            <b-button :disabled="!trainees_filter" @click="trainees_filter = null">Effacer</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                                <router-link :to="{ name: 'trainee.create' }" class="btn btn-clean btn-primary">
                                    Créer un nouveau stagiaire
                                </router-link>
                            </div>
                            <b-table
                                :items="trainees.data"
                                :fields="trainees_fields"
                                :per-page="trainees.meta.per_page"
                                responsive
                                hover
                                style="vertical-align: middle; text-align: center"
                                class="mx-0 p-0"
                                :class="mobile ? 'mb-5 mt-0' : 'my-5'"
                            >
                                <template #cell(customer)="row">
                                    <div class="d-flex flex-column justify-content-around align-items-center" v-if="row.item.customer">
                                        {{ row.item.customer.name }}
                                        <span class="text-muted"> ( {{ row.item.customer.code }} ) </span>
                                    </div>
                                </template>
                                <template #cell(Action)="row">
                                    <div class="d-flex flex-column justify-content-around align-items-center">
                                        <button
                                            class="btn btn-clean btn-sm btn-primary btn-icon"
                                            @click="selectUser(row.item)"
                                            v-b-tooltip.hover.left="'Choisir cet utilisateur'"
                                        >
                                            <i class="fas fa-user-cog"></i>
                                        </button>
                                    </div>
                                </template>
                            </b-table>
                            <div
                                class="d-flex align-items-end"
                                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                                style="width: 100%"
                            >
                                <b-col sm="5" md="4">
                                    <b-pagination
                                        v-model="trainees_current_page"
                                        :total-rows="trainees.meta.total"
                                        :per-page="trainees.meta.per_page"
                                        align="fill"
                                    >
                                    </b-pagination>
                                </b-col>
                                <div>
                                    <ul class="pagination" :class="mobile ? 'mt-2' : ''">
                                        <li class="page-item active">
                                            <a class="page-link bg-primary"
                                                >Nombre de résultats : {{ trainees.meta.total ? trainees.meta.total : '' }}</a
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6">
                <div
                    class="card card-custom gutter-b mt-5 p-5"
                    :class="selectedUser && selectedClasse && error ? 'border border-danger shadow shadow-danger' : ''"
                >
                    <div class="card-body justify-content-around text-center p-0">
                        <h4 class="card-title">Classe</h4>
                        <div v-if="selectedClasse">
                            <div class="d-flex flex-row flex-wrap justify-content-center align-items-center my-3">
                                <div style="max-width: 20%" class="mx-5">
                                    <img
                                        :src="`${selectedClasse.classe ? selectedClasse.classe.miniature : selectedClasse.miniature}`"
                                        width="100%"
                                        alt="image de la classe"
                                    />
                                </div>
                                <div class="d-flex flex-column justify-content-around">
                                    <p class="text-primary h3 py-1">
                                        {{ `${selectedClasse.classe ? selectedClasse.classe.title : selectedClasse.title}` }}
                                    </p>
                                    <div v-if="selectedClasse.description || selectedClasse.classe.description" class="text-muted mb-2">
                                        {{ `${selectedClasse.classe ? selectedClasse.classe.description : selectedClasse.description}` }}
                                    </div>
                                    <div
                                        v-if="selectedClasse.customer"
                                        class="svg-icon svg-icon-primary text-primary d-flex justify-content-start align-items-end"
                                    >
                                        <inline-svg src="/media/svg/icons/Home/Building.svg" class="mr-2" />
                                        <span>Gestionnaire : {{ selectedClasse.customer.name.toUpperCase() }}</span>
                                    </div>
                                    <div
                                        v-if="selectedClasse.left"
                                        class="svg-icon d-flex justify-content-start align-items-end"
                                        :class="`${
                                            selectedClasse.left <= 50
                                                ? 'text' +
                                                  `${selectedClasse.left <= 10 ? '-danger' : '-warning'}` +
                                                  ' svg-icon' +
                                                  `${selectedClasse.left <= 10 ? '-danger' : '-warning'}`
                                                : 'svg-icon-primary text-primary'
                                        }`"
                                    >
                                        <inline-svg
                                            v-if="selectedClasse.left <= 50"
                                            src="/media/svg/icons/Code/Warning-2.svg"
                                            class="mr-2"
                                        />
                                        <inline-svg v-else src="/media/svg/icons/General/Clipboard.svg" class="mr-2" />
                                        <span>Licences restantes : {{ selectedClasse.left }}</span>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-clean btn-danger mt-4" @click="unselectClasse()">Choisir une autre classe</button>
                        </div>
                        <div v-else class="border-0 m-0 d-flex flex-wrap align-items-center mt-5 justify-content-center">
                            <h3 v-if="$store.getters.currentUser.role === 'admin' && client" class="text-primary center mt-0 mb-5">
                                Liste des classes disponible pour le client
                                {{ client && client.name.toUpperCase() }}
                            </h3>
                            <div
                                class="border-0 m-0 d-flex flex-wrap align-items-center mb-5"
                                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                                style="width: 100%"
                            >
                                <b-form-group style="width: 100%" class="m-0" label-for="classe_filterInput" v-if="selectedUser">
                                    <b-input-group>
                                        <b-form-input
                                            v-model="classe_filter"
                                            type="search"
                                            id="classe_filterInput"
                                            placeholder="Rechercher"
                                        >
                                        </b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!classe_filter" @click="classe_filter = ''">Effacer</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                            <b-table
                                v-if="selectedUser"
                                :items="classes.data"
                                :fields="classe_fields"
                                :sort-by.sync="classe_sortBy"
                                :sort-desc.sync="classe_sortDesc"
                                :per-page="classe_perPage"
                                :filter="classe_filter"
                                @filtered="onFiltered"
                                :current-page="classe_currentPage"
                                responsive
                                hover
                                no-border-collapse
                                show-empty
                                style="vertical-align: middle; text-align: center"
                                class="mx-0 p-0"
                                :class="mobile ? 'mb-5 mt-0' : 'my-5'"
                                :busy.sync="classe_loading"
                            >
                                <template #cell(title)="row">
                                    <div
                                        class="text-truncate customScroll"
                                        style="
                                            white-space: break-spaces;
                                            margin: 0 auto;
                                            max-width: fit-content;
                                            max-height: 4vh;
                                            overflow-y: auto;
                                        "
                                    >
                                        {{ row.item.classe.title }}
                                    </div>
                                </template>
                                <template #cell(description)="row">
                                    <div
                                        class="text-truncate customScroll"
                                        style="
                                            white-space: break-spaces;
                                            margin: 0 auto;
                                            max-width: fit-content;
                                            max-height: 4vh;
                                            overflow-y: auto;
                                        "
                                    >
                                        {{ row.item.classe.description }}
                                    </div>
                                </template>
                                <template #cell(on)="row">
                                    <div class="d-flex flex-column justify-content-around align-items-center">
                                        <div v-if="row.item.classe.on" class="text-primary">En ligne</div>
                                        <div v-else class="text-danger">Hors ligne</div>
                                    </div>
                                </template>
                                <template #cell(customer)="row">
                                    <div class="text-center">
                                        <p class="mb-0">{{ row.item.customer.name.toUpperCase() }}</p>
                                        <span class="text-muted">({{ row.item.customer.code }})</span>
                                    </div>
                                </template>
                                <template #cell(Action)="row">
                                    <div
                                        class="d-flex flex-row justify-content-around align-items-center"
                                        v-b-tooltip.hover.left="
                                            `${
                                                row.item.classe.on &&
                                                (!row.item.date_limit ||
                                                    (row.item.date_limit && new Date(row.item.date_limit) > new Date()))
                                                    ? 'Choisir cette classe'
                                                    : row.item.classe.on &&
                                                      row.item.date_limit &&
                                                      new Date(row.item.date_limit) < new Date()
                                                    ? 'La licence de cette classe est expirée'
                                                    : 'Vous ne pouvez pas choisir de classe hors-ligne'
                                            }`
                                        "
                                    >
                                        <button
                                            class="btn btn-md btn-icon"
                                            :class="
                                                row.item.classe.on && (!row.item.date_limit || new Date(row.item.date_limit) > new Date())
                                                    ? 'btn-primary'
                                                    : 'btn-danger'
                                            "
                                            @click="selectClasse(row.item)"
                                            :disabled="
                                                !row.item.classe.on || (row.item.date_limit && new Date(row.item.date_limit) < new Date())
                                            "
                                        >
                                            <i :class="row.item.classe.on ? 'fas fa-book-medical' : 'fas fa-book'"></i>
                                        </button>
                                    </div>
                                </template>
                                <template #empty="scope">Aucun résultats</template>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong class="ml-2">Chargement ...</strong>
                                    </div>
                                </template>
                            </b-table>
                            <strong v-else>Veuillez sélectionner un stagiaire.</strong>
                            <b-row
                                class="d-flex align-items-center"
                                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                                style="width: 100%"
                            >
                                <b-col sm="5" md="4">
                                    <b-pagination
                                        v-model="classe_currentPage"
                                        :total-rows="classe_totalRows"
                                        :per-page="classe_perPage"
                                        align="fill"
                                    >
                                    </b-pagination>
                                </b-col>
                                <!-- <div>
                                    <ul class="pagination" :class="mobile ? 'mt-2' : ''">
                                        <li class="page-item active">
                                            <a class="page-link bg-primary"
                                                >Nombre de résultats : {{ this.classes ? this.classes.meta.total : '' }}</a
                                            >
                                        </li>
                                    </ul>
                                </div> -->
                            </b-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-b-tooltip.hover="
                (!selectedUser || !selectedClasse) && error
                    ? 'Vous devez sélectionner une classe et un stagiaire'
                    : error && selectedClasse.left < 1
                    ? 'Vous n\'avez plus assez de license'
                    : ''
            "
        >
            <button
                :disabled="!selectedUser || !selectedClasse || error"
                class="w-50 mx-auto mt-5 btn btn-primary btn-block py-5 text-center text-white"
                v-b-modal.assign-confirm-modal
            >
                Ouvrir l'accès
            </button>
        </div>
        <b-modal id="assign-confirm-modal" header-text-variant="danger" title="Êtes-vous sûr(e) ?">
            <div v-if="selectedUser && selectedClasse && !error" class="d-flex flex-column justify-content-around">
                <span class="text-primary"
                    >Classe séléctionée :
                    <strong>{{ `${selectedClasse.classe ? selectedClasse.classe.title : selectedClasse.title}` }}</strong></span
                >
                <span class="text-primary"
                    >Stagiaire séléctioné : <strong>{{ selectedUser.lastname.toUpperCase() + ' ' + selectedUser.firstname }}</strong></span
                >
                <div v-if="selectedClasse.customer" class="d-flex flex-column mt-5 text-center border-bottom border-top border-secondary">
                    <p class="text-primary mt-2">
                        Solde de licences du client
                        <strong>{{ selectedClasse.customer && selectedClasse.customer.name.toUpperCase() }}</strong>
                        <span class="text-muted">({{ selectedClasse.customer && selectedClasse.customer.code }})</span> :
                    </p>
                    <strike class="text-warning"> Actuellement : {{ selectedClasse.left }}</strike>
                    <p class="text-primary">Prochain solde : {{ parseInt(selectedClasse.left) - 1 }}</p>
                </div>
                <div v-if="selectedClasse.customer" class="text-muted mt-10">
                    Si vous annulez maintenant, aucune licence ne sera utilisée et l'accès ne sera pas ouvert
                    <br /><br />
                    Si vous confirmez votre choix, vous ne pourrez ni annuler l'ouverture ni récuperer la licence !
                </div>
            </div>
            <div
                v-if="submit"
                style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                    background-color: #ffffff9e;
                    backdrop-filter: blur(5px);
                "
                class="d-flex flex-column justify-content-around align-items-center"
            >
                <div style="z-index: 200" class="d-flex flex-column justify-content-around align-items-center">
                    <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
                    <p class="text-primary py-2 my-2">Enregistrement en cours ...</p>
                </div>
            </div>
            <div
                v-if="open && open != 'fail' && !submit"
                style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                    background-color: #ffffff9e;
                    backdrop-filter: blur(5px);
                "
                class="d-flex flex-column justify-content-around align-items-center"
            >
                <div style="z-index: 200" class="d-flex flex-column justify-content-around align-items-center">
                    <p class="text-success py-2 my-2">L'enregistrement est terminé</p>
                </div>
            </div>
            <div
                v-else-if="open == 'fail' && !submit"
                style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                    background-color: #ffffff9e;
                    backdrop-filter: blur(5px);
                "
                class="d-flex flex-column justify-content-around align-items-center"
            >
                <div style="z-index: 200" class="d-flex flex-column justify-content-around align-items-center">
                    <p class="text-danger py-2 my-2">L'enregistrement à échoué</p>
                </div>
            </div>
            <template #modal-footer>
                <b-button variant="primary" v-if="selectedUser && selectedClasse && !error && !open" @click="assign()">
                    Je confirme
                </b-button>
                <span v-else-if="open && open != 'fail'">
                    <router-link :to="prevRoute ? prevRoute.fullPath : '/'" class="btn btn-success">Terminer</router-link>
                    <b-button variant="primary" class="mx-2" @click="reset()"> Nouvelle inscription </b-button>
                </span>
                <b-button variant="primary" v-else @click="assign()"> Réessayer </b-button>
            </template>
        </b-modal>
    </div>
    <!--end::Dashboard-->
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { LIST_TRAINEES } from '@/core/services/store/api/trainee.service'; // SI ADMIN => Tous les users
import { LIST_CLIENTS_CLASSES } from '@/core/services/store/api/client-classe.service';
// import { LIST_CLASSES } from '@/core/services/store/api/client.service'; // SI Gestionnaire => Les classes que le client possède
// import { LIST_INFO_CLIENT } from '@/core/services/store/api/client.service';
// import { LIST_CUSTOMER_TRAINEE } from '@/core/services/store/api/trainee.service'; // SI ADMIN => Toutes les classes online // SI Gestionnaire => Les users rattaché au client
// import { LIST_ONLINE_CLASSES } from '@/core/services/store/api/classe.service';
import { USER_HAVE_CLASSE } from '@/core/services/store/api/classe_users.service';
import { mapGetters } from 'vuex';
import ApiService from '@/core/services/api.service';
import moment from 'moment';

export default {
    name: 'admin-assign-user',
    components: {
        //Nl2br
    },
    data() {
        return {
            selectedUser: {},
            selectedClasse: {},
            prevRoute: null,
            loadingClasse: true,
            loadingTrainee: true,
            user: null,
            customerclasse: null,
            client: null,
            online_classes: null,
            error: false,
            open: false,
            submit: false,
            trainees_filter: null,
            trainees_current_page: 1,
            trainees_fields: [
                { key: 'firstname', label: 'Prénom', sortable: true },
                { key: 'lastname', label: 'Nom', sortable: true },
                { key: 'customer', label: 'Établissement', sortable: true },
                { key: 'Action', label: 'Actions', sortable: false }
            ],
            classe_loading: false,
            classe_currentPage: 1,
            classe_totalRows: 1,
            classe_perPage: 10,
            classe_pageOptions: [10, 25, 50, { value: 100, text: 'Le plus possible' }],
            classe_filter: null,
            classe_isBusy: false,
            classe_sortBy: 'title',
            classe_sortDesc: false,
            classe_fields: [
                { key: 'title', thStyle: 'max-width: 120px', label: 'Titre', sortable: true },
                { key: 'description', thStyle: 'max-width: 120px', label: 'Description', sortable: true },
                { key: 'on', label: 'Statut', sortable: true },
                { key: 'customer', label: 'Gestionnaire', sortable: true },
                { key: 'left', label: 'Licences restantes', sortable: true },
                { key: 'Action', label: '', thStyle: 'width: 5%', sortable: false }
            ]
        };
    },
    mounted() {
        //this.$store.getters.currentUser.role = 'gestionnaire';
        this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Affecter une classe a un stagiaire' }]);

        this.selectedUser = this.$route.params.user;
        this.selectedClasse = this.$route.params.customerclasse;

        this.listTrainees();
        this.listClientsClasses();
    },
    computed: {
        ...mapGetters({
            classes: 'getClientsClasses',
            trainees: 'getTrainees'
        }),
        mobile() {
            return window.screen.availWidth > 992 ? false : true;
        }
    },
    watch: {
        trainees_current_page: function () {
            this.listTrainees();
        },
        trainees_filter: function () {
            this.listTrainees();
        }
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.classe_totalRows = filteredItems.length;
        },
        checkTraineeClasse() {
            if (this.selectedUser && this.selectedClasse) {
                if (this.selectedClasse.left < 1) {
                    this.error = true;
                } else {
                    this.$store
                        .dispatch(USER_HAVE_CLASSE, {
                            user_id: this.selectedUser.id,
                            classe_id: this.selectedClasse.classe ? this.selectedClasse.classe.id : this.selectedClasse.id
                        })
                        .then((result) => {
                            this.error = !!result.data;
                        });
                }
            }
        },
        listTrainees() {
            let data = {
                params: {
                    page: this.trainees_current_page,
                    'filter[name]': this.trainees_filter
                }
            };
            if (this.selectedClasse && this.selectedClasse.customer) {
                data.params['customer_id'] = this.selectedClasse.customer.id;
            }

            this.$store.dispatch(LIST_TRAINEES, data);
        },
        listClientsClasses() {
            this.classe_loading = true;
            let param = {
                params: {
                    // page: this.classe_currentPage
                }
            };
            if (this.selectedUser && this.selectedUser.customer) {
                param.params.customer_id = this.selectedUser.customer.id;
            }
            this.$store
                .dispatch(LIST_CLIENTS_CLASSES, param)
                .catch(() => {
                    this.classe_loading = false;
                })
                .then(() => {
                    this.classe_totalRows = this.classes.data.length;
                    this.classe_loading = false;
                });
        },
        selectUser(user) {
            this.selectedUser = user;
            this.checkTraineeClasse();
            this.listClientsClasses();
        },
        unselectUser() {
            this.selectedUser = null;
            this.listTrainees();
            if (!this.selectedClasse) {
                this.listClientsClasses();
            }
        },
        selectClasse(classe) {
            this.selectedClasse = classe;
            this.checkTraineeClasse();
            this.listTrainees();
        },
        unselectClasse() {
            this.selectedClasse = null;
            this.listClientsClasses();
            if (!this.selectedClasse) {
                this.listTrainees();
            }
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        assign() {
            if (this.selectedClasse.left - 1 >= 0) {
                this.submit = true;
                let classe_id = this.selectedClasse.classe ? this.selectedClasse.classe.id : this.selectedClasse.id;
                ApiService.post(process.env.VUE_APP_API_URL + '/academy/classeUsers', {
                    user_id: this.selectedUser.id,
                    classe_id: classe_id,
                    customer_id: `${this.selectedClasse.customer ? this.selectedClasse.customer.id : 'NULL'}`,
                    classes_customer_id: this.selectedClasse.id
                }).then((result) => {
                    if (result.data) {
                        if (this.selectedClasse.customer) {
                            ApiService.post(process.env.VUE_APP_API_URL + '/academy/clientclasses/open', {
                                classe_id: classe_id,
                                customer_id: `${this.selectedClasse.customer ? this.selectedClasse.customer.id : 'NULL'}`
                            }).then((result) => {
                                if (result.data) {
                                    if (this.$route.params.user) {
                                        this.unselectClasse();
                                    } else {
                                        this.unselectUser();
                                    }
                                    this.open = true;
                                }
                                this.submit = false;
                            });
                        } else {
                            this.open = true;
                        }
                        this.submit = false;
                    } else {
                        this.open = 'fail';
                    }
                    this.submit = false;
                });
            } else {
                this.open = 'fail';
            }
        },
        reset() {
            this.submit = false;
            this.open = false;
            this.$bvModal.hide('assign-confirm-modal');
            /* this.$forceUpdate(); */
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    }
};
</script>
<style scoped>
td {
    vertical-align: middle !important;
}
.customScroll::-webkit-scrollbar-track {
    background-color: #eee;
}

.customScroll::-webkit-scrollbar {
    width: 2px;
    background-color: #eee;
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #085a82;
}
</style>
