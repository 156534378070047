<template>
    <div>
        <div v-if="loading" class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5">Chargement ...</div>
        <div v-else class="card card-custom col-lg-13 col-xxl-12 mt-5">
            <div>
                <ClassesManagerTable :customerId="customerId"></ClassesManagerTable>
            </div>
        </div>
    </div>
</template>

<script>
import ClassesManagerTable from '@/view/content/widgets/manager/ClassesManagerTableWidget.vue';

export default {
    name: 'admin_dashboard',
    data() {
        return {
            activeDisplay: 0,
            loading: true,
            pageOn: null,
            loading_time: 1500,
            customerId: this.$route.params.id || null
        };
    },
    components: {
        ClassesManagerTable
    },
    mounted() {
        if (this.$store['admin-dashboard-display'] == undefined) {
            this.$store['admin-dashboard-display'] = 0;
        }
        this.activeDisplay = this.$store['admin-dashboard-display'];
        this.pageOn = this.$route.query.activeTable;
        setTimeout(() => {
            this.loading = false;
        }, this.loading_time);
    },
    updated() {
        if (this.$route.query.activeTable != this.pageOn && this.pageOn) {
            this.pageOn = this.$route.query.activeTable;
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, this.loading_time);
        }
    },
    methods: {
        SetDisplay(displayIndex) {
            this.$store['admin-dashboard-display'] = displayIndex;
        }
    }
};
</script>
