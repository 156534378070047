<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card gutter-b">
                    <div class="card-header p-4">
                        <div class="row">
                            <div class="col-lg-3 text-left my-auto">
                                <router-link :to="{ name: 'admin/dashboard', query: { activeTable: 'stagiaires' } }" replace>
                                    <span class="btn btn-outline-danger btn-md">
                                        <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                            <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                                        </span>
                                        Retour
                                    </span>
                                </router-link>
                            </div>
                            <div class="text-center col-lg-6">
                                <h3 class="text-primary">Gestion des inscriptions à une classe</h3>
                                <span class="text-muted font-italic">Séléctionnez un stagiaire et une classe</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-lg-6">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0">Stagiaires</h3>
                    </div>
                    <div class="card-body p-0">
                        <trainee-table :isBusy="loadingTrainee" :trainees="trainees" />
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-lg-6">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0">Classes</h3>
                    </div>
                    <div class="card-body p-0">
                        <class-table :isBusy="loadingClass" :classes="classes" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TraineeTable from './components/TraineeTable.vue';
import ClassTable from './components/ClassTable.vue';
import { LIST_TRAINEES } from '@/core/services/store/api/trainee.service';
/* import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
 // SI ADMIN => Tous les users
import { LIST_ONLINE_CLASSES } from '@/core/services/store/api/classe.service'; // SI ADMIN => Toutes les classes online
import { LIST_CUSTOMER_TRAINEE } from '@/core/services/store/api/trainee.service'; // SI Gestionnaire => Les users rattaché au client
import { LIST_CLASSES } from '@/core/services/store/api/client.service'; // SI Gestionnaire => Les classes que le client possède
import { LIST_INFO_CLIENT } from '@/core/services/store/api/client.service';
import { USER_HAVE_CLASSE } from '@/core/services/store/api/classe_users.service';
import moment from 'moment'; */

export default {
    components: {
        TraineeTable,
        ClassTable
    },
    data() {
        return {
            prevRoute: null,
            loadingClass: true,
            loadingTrainee: true,
            trainees: {
                fields: [
                    { key: 'firstname', label: 'Prénom', sortable: true },
                    { key: 'lastname', label: 'Nom', sortable: true },
                    { key: 'customer', label: 'Établissement', sortable: true },
                    { key: 'Action', label: 'Actions', sortable: false }
                ],
                data: [],
                meta: {
                    total: null,
                    current_page: null,
                    per_page: null
                }
            },
            classes: {
                fields: [
                    { key: 'title', thStyle: 'max-width: 120px', label: 'Titre', sortable: true },
                    { key: 'description', thStyle: 'max-width: 120px', label: 'Description', sortable: true },
                    { key: 'on', label: 'Statut en ligne', sortable: true },
                    { key: 'customer', label: 'Gestionnaire', sortable: true },
                    { key: 'left', label: 'Licences restantes', sortable: true },
                    { key: 'Action', label: '', thStyle: 'width: 5%', sortable: false }
                ],
                data: [],
                meta: {
                    total: null,
                    current_page: null,
                    per_page: null
                }
            }
        };
    },
    mounted() {
        this.getTrainees();
    },
    methods: {
        getTrainees() {
            var params = {
                params: {
                    page: this.trainees.meta.current_page,
                    perpage: this.trainees.meta.per_page
                }
            };
            this.$store.dispatch(LIST_TRAINEES, params).then(() => {
                this.trainees = this.$store.getters.getTrainees;
                this.loadingTrainee = false;
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    }
};
</script>
<style>
td {
    vertical-align: middle !important;
}
</style>
